:root {
  --theme-text-color: #434343; /* gray-9   */
  --theme-color: #fadb14; /* yellow-6 */
  --theme-color-light: #fffbe6; /* gold-1   */
  --theme-color-medium: #ffffb8; /* yellow-2 */
  --theme-color-dark: #d4b106; /* yellow-7 */
  --theme-color-accent: #fa8c16; /* orange-6 */
}

/* Override antd theme */

.ant-layout-header {
  color: var(--theme-text-color);
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.ant-btn-primary,
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--theme-text-color);
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.ant-btn:hover,
.ant-btn:focus,
.ant-radio-button-wrapper:hover,
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  color: var(--theme-color-accent);
  background-color: var(--theme-color-light);
  border-color: var(--theme-color-accent);
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: var(--theme-color);
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):active::before,
.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover::before {
  background-color: var(--theme-color-accent);
}

.ant-radio-button-wrapper-checked:not(
    [class*=' ant-radio-button-wrapper-disabled']
  ).ant-radio-button-wrapper:first-child {
  border-right-color: var(--theme-color);
}

.ant-radio-button-wrapper-checked:not(
    [class*=' ant-radio-button-wrapper-disabled']
  ).ant-radio-button-wrapper:first-child:hover {
  border-right-color: var(--theme-color-accent);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--theme-color-dark);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--theme-color-accent);
  border-color: var(--theme-color-accent);
}

.ant-checkbox-checked::after {
  border-color: var(--theme-color);
}

.ant-btn:active {
  color: var(--theme-color-dark);
  background-color: var(--theme-color-medium);
}

.ant-btn-link {
  color: var(--theme-color-dark);
}

.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input:hover,
.ant-input:focus,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector,
.ant-picker:hover,
.ant-picker-focused,
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: var(--theme-color);
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 4px var(--theme-color-light);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--theme-color-light);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: var(--theme-color-accent);
}

.ant-switch-checked {
  background-color: var(--theme-color-dark);
}

.ant-input:focus,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector,
.ant-picker-focused {
  box-shadow: 0 0 0 4px var(--theme-color-light);
}

.ant-input-group-wrapper.ant-input-search button {
  z-index: 1;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: var(--theme-text-color);
  background-color: var(--theme-color);
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background-color: var(--theme-color);
}

.ant-picker-today-btn {
  color: var(--theme-color-dark);
}

.ant-picker-today-btn:hover,
.ant-picker-today-btn:active {
  color: var(--theme-color-accent);
}

.ant-picker-header-view button:hover {
  color: var(--theme-color-dark);
}

.ant-picker-range .ant-picker-active-bar {
  background: var(--theme-color-accent);
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: var(--theme-color-light);
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ).ant-picker-cell-range-hover-end::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background: var(--theme-color-medium);
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(
    .ant-picker-cell-range-hover-edge-start-near-range
  )::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  border-left: 1px dashed var(--theme-color-accent);
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(
    .ant-picker-cell-in-range
  ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(
    .ant-picker-cell-in-range
  ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(
    .ant-picker-cell-in-range
  )::after {
  border-top: 1px dashed var(--theme-color-accent);
  border-bottom: 1px dashed var(--theme-color-accent);
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: var(--theme-color);
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: var(--theme-color);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--theme-color-dark);
}

.ant-tabs-ink-bar {
  background-color: var(--theme-color-dark);
  border-bottom: 2px solid var(--theme-color-dark);
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active,
.ant-tabs-tab:hover,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: var(--theme-color-accent);
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: var(--theme-color-accent);
}

.ant-pagination-item-active {
  border-color: var(--theme-color-dark);
  background-color: var(--theme-color-light);
}

.ant-pagination-item-active a {
  color: var(--theme-color-dark);
}

.ant-pagination-item:focus,
.ant-pagination-item:hover,
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: var(--theme-color-accent);
}

.ant-pagination-item:focus a,
.ant-pagination-item:hover a,
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: var(--theme-color-accent);
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: var(--theme-color-accent);
  color: var(--theme-color-accent);
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
}

.ant-pagination-total-text {
  width: 100%;
}

.ant-table-pagination.ant-pagination:first-child {
  margin-top: 0;
}

.ant-table-pagination.ant-pagination:last-child {
  margin-bottom: 0;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu.selected {
  color: var(--theme-color-accent);
  border-bottom: 2px solid var(--theme-color-accent);
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover,
.ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-submenu-selected:hover .ant-dropdown-menu-submenu-title {
  background-color: transparent;
  color: var(--theme-color-dark);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover {
  border-bottom: 2px solid var(--theme-color-dark);
  color: var(--theme-color-dark);
}

.ant-menu-submenu-selected,
.ant-menu-submenu.selected {
  color: var(--theme-text-color);
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-color: var(--theme-color-accent);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a,
.ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-submenu-title-selected:hover {
  background-color: var(--theme-color-light);
  color: var(--theme-color-accent);
}

.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: rgba(0, 0, 0, 0.85);
}

.ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path {
  stroke: var(--theme-color);
}

/* Responsiveness */

.ant-table-content {
  overflow-x: scroll;
}

.ant-table table {
  width: auto;
  min-width: 100%;
}

.ant-table-expanded-row > .ant-table-cell {
  max-width: 0;
}

@media (max-width: 480px) {
  .ant-table,
  .ant-upload-list-item {
    font-size: 12px;
  }
  .ant-upload-list-picture .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    width: 32px;
    height: 32px;
    line-height: 38px;
  }
  .ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
    font-size: 20px;
  }
  .ant-upload-list-text .ant-upload-list-item-name,
  .ant-upload-list-picture .ant-upload-list-item-name {
    padding: 0px;
  }
}

/* Global styles */

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: #333;
  background-color: #f0f2f5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
:focus {
  outline: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'PT Serif', serif;
}

input[type='file'] {
  width: 100%;
}

a {
  color: var(--theme-color-dark);
}

a:hover {
  color: var(--theme-color-accent);
}

/* Helper classes */

.clickable {
  cursor: pointer;
}

.center {
  text-align: center;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}
